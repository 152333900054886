<template>
  <a-card :bordered="false">
    <div class="meeting_warp">
      <!-- 搜索区域 -->
      <div class="info_search">
        <a-row :gutter="24">
          <a-col :md="6" :sm="6">
            <a-input class="search_input" v-model="queryParam.materialCode" placeholder="请输入物料编码" />
          </a-col>
          <a-col :md="6" :sm="6">
            <a-input class="search_input" v-model="queryParam.standardNo" placeholder="请输入标准号" />
          </a-col>
          <a-col :md="6" :sm="6">
            <a-input class="search_input" v-model="queryParam.modelNo" placeholder="请输入型号" />
          </a-col>
          <a-col :md="6" :sm="6">
            <a-input class="search_input" v-model="queryParam.specifications" placeholder="请输入规格" />
          </a-col>
          <br/> <br/> <br/>
          <a-col :md="6" :sm="6">
            <a-input class="search_input" v-model="queryParam.materialDescription" placeholder="请输入物料描述" />
          </a-col>
          <a-col :md="6" :sm="6">
            <a-input class="search_input" v-model="queryParam.brand" placeholder="请输入品牌" />
          </a-col>
          <a-col :md="6" :sm="6">
            <a-select v-model="queryParam.materialType" placeholder="请选择物料类型" style="width: 100%;" :allowClear="true">
              <a-select-option :key="undefined" :value="undefined">请选择物料类型</a-select-option>
              <a-select-option v-for="text in materialTypeList" :key="text"  :value="text"> {{ text }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :md="3" :sm="3">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons" >
              <a-button class="search_btn search_query " style="width:calc(50%-3px)" type="primary" @click="searchQuerys" >查询</a-button>
              <a-button class="search_btn" style="margin-left: 10px; width:calc(50%-3px)" @click="searchReset" >重置</a-button>
            </span>
          </a-col>
        </a-row>
      </div>
      <!-- table区域 -->
      <div class="info_table">
        <a-table
          ref="table"
          size="middle"
          :scroll="{x:true}"
          bordered
          rowKey="id"
          :columns="columnsAdmin"
          :dataSource="dataSource"
          :pagination="ipagination"
          :loading="loading"
          @change="handleTableChange">
        </a-table>
      </div>
      <br/>
    </div>

  </a-card>
</template>

<script>
import Vue from 'vue'
import { JeecgListMixin } from '@/views/mixins/mixin'
import { USER_INFO } from "@/store/mutation-types"
import { getAction } from '@/api/manage'
import { MESNAC_MEETING_RESERVE_URL } from '@/views/center/modules/config'

export default {
  mixins:[JeecgListMixin],
  components: { },
  data() {
    return {
      adminURL: window._CONFIG['adminURL'],
      userInfo: Vue.ls.get(USER_INFO),
      workNo:Vue.ls.get(USER_INFO).workNo,
      newUrl: MESNAC_MEETING_RESERVE_URL,
      isAdmin: false,
      materialTypeList : ['标准件','外购件','大宗','非生产性物料'],
      columnsAdmin: [
        {
          title: '序号',
          dataIndex: '',
          key:'rowIndex',
          width:60,
          align:"center",
          customRender:function (t,r,index) {
            return parseInt(index)+1;
          }
        },
        {
          title:'物料编码',
          align:"left",
          dataIndex: 'materialCode'
        },
        {
          title:'物料描述',
          align:"left",
          dataIndex: 'materialDescription',
          width:200,
        },
        // {
        //   title:'物料类型',
        //   align:"center",
        //   dataIndex: 'materialType'
        // },
        {
          title:'单位',
          align:"left",
          dataIndex: 'unit'
        },
        {
          title:'名称',
          align:"left",
          dataIndex: 'name',
          width:150,
        },
        {
          title:'标准号',
          align:"left",
          dataIndex: 'standardNo'
        },
        {
          title:'型号',
          align:"left",
          dataIndex: 'modelNo'
        },
        {
          title:'规格',
          align:"left",
          dataIndex: 'specifications'
        },
        {
          title:'品牌',
          align:"left",
          dataIndex: 'brand'
        },
        {
          title:'材料牌号',
          align:"left",
          dataIndex: 'materialNo'
        },
        {
          title:'强度等级',
          align:"left",
          dataIndex: 'strengthGrade'
        },
        {
          title:'PDM系统状态',
          align:"left",
          dataIndex: 'pdmStatus'
        },
        {
          title:'SAP系统状态',
          align:"left",
          dataIndex: 'sapStatus'
        },
        {
          title:'代用物料编码',
          align:"left",
          dataIndex: 'substituteMaterialCode'
        },
        {
          title:'优选标识',
          align:"left",
          dataIndex: 'preferredIdentification'
        },
        {
          title:'采购经理',
          align:"left",
          dataIndex: 'purchasingManager'
        },
      ],
      disableMixinCreated: true,
      queryParam:{
        status:'0',// 是否查询状态
      },
      url: {
        list: '/mesnac/material/list'//查询接口
      },
    }
  },
  created() {
    /**检测管理员*/
    this.checkAdmin()
    /**查询接口*/
    this.loadData()
  },
  methods: {
    /**检测管理员*/
    checkAdmin(){
      getAction(this.newUrl.checkUserIsAdmin, {workNo:this.workNo}).then(res => {
        console.log('checkAdmin res: ', res);
        if (res.success){
          this.isAdmin = res.result
        }else{
          this.$error({title: '检测管理员权限异常', content: res.message})
        }
      })
    },

    /**查询按钮*/
    searchQuerys() {
      /**判断查询属性是否全部为空字符串,统一赋值undefined 后面判断*/
      /**物料编码*/
      if (this.queryParam.materialCode=="" ){
        this.queryParam.materialCode=undefined
      }
      /**标准号*/
      if (this.queryParam.standardNo=="" ){
        this.queryParam.standardNo=undefined
      }
      /**型号*/
      if (this.queryParam.modelNo=="" ){
        this.queryParam.modelNo=undefined
      }
      /**物料描述*/
      if (this.queryParam.specifications=="" ){
        this.queryParam.specifications=undefined
      }
      /**物料描述*/
      if (this.queryParam.materialDescription=="" ){
        this.queryParam.materialDescription=undefined
      }
      /**品牌*/
      if (this.queryParam.brand=="" ){
        this.queryParam.brand=undefined
      }
      /**物料类型*/
      if (this.queryParam.materialType=="" ){
        this.queryParam.materialType=undefined
      }
      /**判断查询属性是否全部为空*/
      if (this.queryParam.materialCode==undefined
        && this.queryParam.standardNo==undefined
        && this.queryParam.modelNo==undefined
        && this.queryParam.specifications==undefined
        && this.queryParam.materialDescription==undefined
        && this.queryParam.brand==undefined
        && this.queryParam.materialType==undefined){
        this.$message.warning("至少输入一个条件进行查询！")
        return
      }
      /**物料编码不为空时,物料编码英文字母转大写*/
      if (this.queryParam.materialCode!=undefined){
        this.queryParam.materialCode=this.queryParam.materialCode.toUpperCase();
      }
      this.queryParam.status='1'
      /**查询接口*/
      this.loadData(1);
    },
    /**重置按钮*/
    searchReset() {
      this.queryParam = {};
      this.queryParam.status='0'
      this.loadData(1);
    },

  }
}
</script>
<style lang="less" scoped>
// 修改tabs样式
/deep/.ant-tabs{
  .ant-tabs-nav-container , .ant-tabs-nav-wrap , .ant-tabs-nav-scroll{
    // background-color:rgba(188, 0, 57, 100);
    background: #fff;
    height: 70px;
    border-bottom: 1px solid #ccc;
    .ant-tabs-nav{
      width: 100%;
      margin-top:13px;
      height: 60px;
      .ant-tabs-tab{
        height: 60px;
        padding: 12px 0px;
        line-height: 30px;
        width: 10%;
        margin: 0px;
        text-align: center;
        color:#101010;
        font-size: 22px;
        &::after {
          content: "";
          display: inline-block;
          height: 2px;
          width: 100%;
          position: absolute;
          left:0px;
          background: #fff;
          bottom:2px;
          z-index: 100;
        }
      }
      .ant-tabs-tab-active{
        height: 58px;
        color: rgba(188, 0, 57, 100);
        background: #fff;
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          height: 2px;
          width: 100px;
          position: absolute;
          left:50%;
          margin-left: -50px;
          background: #BC0039;
          bottom:2px;
          z-index: 100;
        }
      }
      .ant-tabs-ink-bar, .ant-tabs-ink-bar-animated {
        display: none !important;
      }
      .ant-tabs-content{
        background: #fff;
      }
    }
  }
}
.meeting_warp{
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  min-height: 800px;
  position: relative;
  // background: url('~@/assets/img/home_bg.png') no-repeat top center / cover;
  background-size: 100%;
  .meeting_content{
    width: 1500px;
    margin: 0 auto;
    min-height: 600px;
    .img_warp{
      margin: 0px auto;
      padding-top: 150px;
      // height: 200px;
      width: 200px;
      text-align: center;
      img{
        width: 100%;
        height: 100%;
      }
      span{
        display: inline-block;
        margin-top: 30px;
        font-size: 14px;
        color: #101010;
      }
    }
    .meeting_reserve{
      background: #fff;
      padding: 20px;
      margin-bottom: 20px;
      .reserve_title{
        margin-bottom:20px;
        font-size: 22px;
        color: #101010;
      }
    }
    .my_reserve{
      background: #fff;
      padding: 20px;
      margin-bottom: 20px;
      .reserve_title{
        margin-bottom:20px;
        font-size: 22px;
        color: #101010;
      }
    }
    .reserve_info{
      background: #fff;
      padding: 20px;
      .reserve_title{
        margin-bottom:20px;
        font-size: 22px;
        color: #101010;
      }
    }
  }
}
.reserve_title{
  margin-bottom:20px;
  font-size: 22px;
  color: #101010;
}
.info_search{
  margin-bottom: 20px;
  .Tips{
    display: inline-block;
    color: #bc0039;
    font-size: 18px;
    line-height: 30px;
  }
  /deep/.ant-col {
    .ant-calendar-picker {
      .ant-calendar-picker-input {
        height: 40px;
        border: 1px solid rgba(188, 0, 57, 100);
        border-radius: 10px;
        color: rgba(188, 0, 57, 100);
        font-size: 18px;
        &::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
          font-size: 18px;
          color: rgba(188, 0, 57, 100);
        }
      }
      .anticon {
        color: #bc0039;
      }
    }
  }
  .search_input {
    height: 40px;
    border: 1px solid rgba(188, 0, 57, 100);
    border-radius: 10px;
    color: rgba(188, 0, 57, 100);
    font-size: 18px;
    &::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      font-size: 18px;
      color: rgba(188, 0, 57, 100);
    }
  }
  .search_btn {
    width: 67px;
    height: 40px;
    text-align: center;
    font-size: 16px;
    color: #bc0039;
    border: 1px solid #bc0039;
    border-radius: 10px;
    padding: 0px;
  }
  .search_query {
    background-color: #ffeded;
  }
}
.info_table{
  /deep/.ant-table {
    color: #101010;
    .ant-table-thead > tr > th{
      color: #101010;
    }
  }
  /deep/ .ant-table th { white-space: nowrap; }
  /deep/.ant-table-placeholder {
    border-bottom: 1px solid #bc0039;
    border-top: 1px solid #bc0039;
  }
  /deep/.ant-table-tbody{
    border-bottom: 1px solid #bc0039;
    border-top: 1px solid #bc0039;
  }
  /deep/.ant-pagination {
    .ant-pagination-next, .ant-pagination-prev {
      .ant-pagination-item-link{
        &:hover{
          color: #BC0039;
        }
      }
    }
    .ant-pagination-next:focus .ant-pagination-item-link{
      color: #BC0039;
    }
    .ant-pagination-item{
      a:hover{
        color: #BC0039;
      }
    }
    .ant-pagination-item-active{
      border-color: #BC0039;
      a{color: #BC0039;}
    }
  }
}


/deep/ .ant-select-selection {
  border: 1px solid #bc0039;
  color: #BC0039;
  height: 2.083vw;
  border-radius: 10px;
  font-size: 0.938vw;

  .ant-select-selection__placeholder, .ant-select-search__field__placeholder{
    color: #BC0039;
  }
}
/deep/ .ant-pagination-options {
  display: none;
}

/deep/ ::selection{
  background: #BC0039;
}
</style>


